$wrap: 600px;

.float {
  margin-bottom: 20px;
  max-width:40%;
}

.float > * {
  margin: 0 !important;
}

.float:not([data-flip]) {
  float: left;
  margin-right: 40px;
}

.float[data-flip] {
  float: right;
  margin-left: 40px;
}

.float[data-clear] {
  float: unset;
  clear: both;
  margin: 0;
}

@media (max-width: $wrap) {
  .float {
    float: unset !important;
    clear: both !important;
    margin: auto !important;
    max-width: unset;
  }
}
